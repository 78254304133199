<template>
  <div>
    <div v-if="!IsDashboard" style="text-align: center">
      <interval-manager
        :title="$t('module.charts.' + Chart.Name)"
        :interval="'Day'"
        :disableIntervalButtons="true"
        :currentDate="currentDate"
        :currentDateText="currentDateText"
        @intervalChanged="intervalChanged"
      />
    </div>
    <ejs-accumulationchart
      :id="'pie-daily-medicine-module' + Id"
      v-if="seriesData"
      :legendSettings="legendSettings"
      :tooltip="IsDashboard ? {} : tooltip"
      enableSmartLables="true"
      :enableAnimation="enableAnimation"
      background="transparent"
      align="center"
      :title="IsDashboard ? $t('module.charts.' + Chart.Name) : ''"
      :titleStyle="IsDashboard ? titleStyle : {}"
      :height="IsDashboard ? '200px' : '110%'"
    >
      <e-accumulation-series-collection>
        <e-accumulation-series
          :dataSource="seriesData"
          :emptyPointSettings="emptyPointSettings"
          :pointColorMapping="pointColorMapping"
          :startAngle="angle.startAngle"
          :endAngle="angle.endAngle"
          :explodeOffset="explodeOffset"
          :explodeIndex="explodeIndex"
          :radius="radius"
          xName="x"
          yName="y"
          :dataLabel="IsDashboard ? null : dataLabel"
          innerRadius="0%"
          explode="true"
        ></e-accumulation-series>
      </e-accumulation-series-collection>
    </ejs-accumulationchart>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import {
  AccumulationChartPlugin,
  AccumulationTooltip,
  PieSeries,
  AccumulationDataLabel,
  Tooltip,
} from "@syncfusion/ej2-vue-charts";
import { ChartUiLogic } from "@/logic/ui/chart-ui";
import { ChartBackendLogic } from "../../../Logic/Backend/chart";
import IntervalManager from "../Components/IntervalManager.vue";

Vue.use(AccumulationChartPlugin);

export default {
  components: { IntervalManager },
  name: "MedicineDaily",
  data() {
    return {
      currentDate: moment(),
      currentDateText: moment().format("LL"),
      interval: "Day",
      //Chart paraméterek
      emptyPointSettings: { mode: "Drop" },
      seriesData: [],
      pointColorMapping: "fill",
      angle: {
        startAngle: 0,
        endAngle: 360,
      },
      dataLabel: {
        visible: true,
        position: "Inside",
        name: "text",
        font: {
          fontWeight: "600",
        },
      },
      enableSmartLabels: true,
      enableAnimation: false,
      legendSettings: {
        visible: false,
      },
      tooltip: { enable: true, format: "${point.x} : <b>${point.y}</b>" },
      radius: "70%",
      explodeOffset: "0%",
      explodeIndex: 0,
      titleStyle: {
        fontFamily: "Avenir, Helvetica, Arial, sans-seri",
        fontWeight: "bold",
        color: "var(--eh-secondary-5)",
      },
    };
  },
  props: {
    PatientId: Number,
    IsDashboard: Boolean,
    Id: [String, Number],
    Chart: Object,
  },
  provide: {
    accumulationchart: [
      PieSeries,
      AccumulationTooltip,
      AccumulationDataLabel,
      Tooltip,
    ],
  },
  methods: {
    //intervallum változás
    async intervalChanged(currentDate, interval) {
      //aktuális dátum tárolás
      this.currentDate = currentDate;
      //aktuális intervallum tárolás
      this.interval = interval;
      //aktuális dátum szöveges tárolása
      this.currentDateText = moment(this.currentDate).format("LL");
      //adatok betöltése
      await this.getChartData();
    },
    //chart adatok kérés
    async getChartData() {
      //kérés válasz
      var getChartDataResponse = null;
      //van betegid?
      if (this.PatientId) {
        //igen, beteg chart adatok betöltése
        getChartDataResponse = await ChartBackendLogic.getPatientChart(
          this.Chart.DatabaseName,
          this.PatientId,
          this.getIntervalParamsToChart()
        );
      } else {
        //nem, saját chart adatok betöltése
        getChartDataResponse = await ChartBackendLogic.getChart(
          this.Chart.DatabaseName,
          this.getIntervalParamsToChart()
        );
      }
      //sikeres kérés?
      if (!getChartDataResponse.Code) {
        //chart adatok beállítása a chartnak
        this.setChartData(getChartDataResponse);
      } else {
        this.$bvToast.toast(getChartDataResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("module.charts." + this.Chart.Name),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //chart intervallum paraméter megadása
    getIntervalParamsToChart() {
      return ChartUiLogic.IntervalManager.getIntervalParamsToChart(
        this.interval,
        this.currentDate
      );
    },
    //adatok beállítása a chartnak
    setChartData(data) {
      //chart lista ürítése
      this.seriesData = [];
      //adat lista nem nulla elemű?
      if (data.length > 0) {
        //bevett gyógyszerek
        const taken = data[0][this.Chart.DataProperties.taken];
        //bevett gyógyszerek %-ban
        const takenPercentage = parseInt(
          data[0][this.Chart.DataProperties.takenPercentage]
        );
        //kihagyott gyógyszerek
        const missed =
          data[0][this.Chart.DataProperties.missed.sum] -
          data[0][this.Chart.DataProperties.missed.taken];
        //kihagyott gyógyszerek %-ban
        const missedPercentage =
          100 - parseInt(data[0].MedicineTakenPercentage);
        //nem, lista megkapja az adatokat konvertált formában
        this.seriesData = [
          {
            x: this.$t("module.charts.collectedMedicines"),
            y: taken > 0 ? taken : null,
            text: takenPercentage + "%",
            fill: "var(--eh-secondary-5)",
          },
          {
            x: this.$t("module.charts.missedMedicines"),
            y: missed > 0 ? missed : null,
            text: missedPercentage + "%",
            fill: "#668293",
          },
        ];
        //tooltip beállítása true
        Vue.set(this.tooltip, "enable", true);
        //adat szövegezés beállítása true
        Vue.set(this.dataLabel, "visible", true);
      } else {
        //igen, chart adatának default érték állítása
        this.seriesData = [
          {
            x: "",
            y: 1,
            fill: "rgb(108,117,125)",
          },
          {
            x: "",
            y: 0,
            fill: "rgb(108,117,125)",
          },
        ];
        //tooltip és adat szöveg kikapcsolás
        Vue.set(this.tooltip, "enable", false);
        Vue.set(this.dataLabel, "visible", false);
      }
    },
  },
  //betöltéskor egyszer lefut
  async mounted() {
    //adat betöltés
    await this.getChartData();
  },
};
</script>

<style scoped>
@import '../style.css';
.chart-title {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: var(--eh-secondary-5);
  font-size: 23px;
}
.dashboard-chart-title {
  text-align: center;
  color: var(--eh-secondary-5);
  cursor: pointer;
  margin-top: 10px;
}
</style>
